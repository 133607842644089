// GaugeChartDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import HalfDoughnutChart from '../components/HalfDoughnutChart';
import MusclePieChart from '../components/MusclePieChart';

const GaugeChartDialog = ({ open, handleClose, data, title }) => {
  const fields = [
    { name: "Metabolism", value: data?.metabolism },
    { name: "Behaviour", value: data?.behaviour },
    { name: "Muscle", value: data?.muscle },
    { name: "Performance", value: data?.performance },
    { name: "Nutrition", value: data?.nutrition },
    { name: "Efficiency", value: data?.efficiency },
    { name: "Recovery", value: data?.recovery },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* 🟢 Gráficos Half Doughnut */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            gap: '20px',
          }}
        >
          {fields.map((field, index) => (
            <Box
              key={index}
              sx={{
                flexBasis: '30%',
                maxWidth: '170px',
                minWidth: '100px',
              }}
            >
              <HalfDoughnutChart
                label={field.name}
                percentage={parseFloat(field.value)}
              />
            </Box>
          ))}
        </Box>

        {/* 🔵 MusclePieChart Centralizado */}
        {data?.muscle && (
          <Box
            sx={{
              mt: 4,                     // Espaçamento superior
              display: 'flex',           // Flexbox para alinhamento
              justifyContent: 'center',  // Centraliza horizontalmente
              alignItems: 'center',      // Centraliza verticalmente
              width: '100%',             // Ocupa toda a largura
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Muscle Score Overview
              </Typography>
              <MusclePieChart
                muscleValue={parseFloat(data.muscle / 100)} // Converte para decimal
                sex={data.sexAtBirth}  // Passa o sexo, se disponível
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GaugeChartDialog;
