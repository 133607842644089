import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Autocomplete } from "@mui/material";
import FooterBar from "../shared/footer";
import Toast from "../shared/toast";
import { apiService } from '../services/apiService';
import { validator } from '../shared/validator';
import { grey } from '@mui/material/colors';

function BePartner() {

  const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    businessType: "",
    businessName: "",
    msg: "",
    captcha: "",
    captchaAnswer: "",
    captchaAnswerback: ""
  };

  const colors = ["red", "green", "blue", "yellow", "purple", "orange", "black", "brown", "pink", "gray"];
  const navigate = useNavigate();
  const [fields, setFields] = useState(initialState);
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [returnto, setReturnto] = useState('/');
  const [emailError, setEmailError] = useState('');
  const [retryDelay, setRetryDelay] = useState(0);
  const [captchaPosition, setCaptchaPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    const captchaColor = colors[randomIndex];
    setFields(prev => ({
      ...prev,
      captcha: captchaColor,
      captchaAnswer: captchaColor
    }));



    const randomTop = 20;
    const randomLeft = Math.floor(Math.random() * 200) + 250;
    setCaptchaPosition({ top: randomTop, left: randomLeft });


  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailValidationMsg = validator.validateEmail(fields.email);
    setEmailError(emailValidationMsg);
    if (emailValidationMsg === '') {
      if (fields.userCaptcha.toLowerCase() !== fields.captchaAnswer) {
        setError(true);
        setmsgToast('CAPTCHA validation failed.<br/>Please try again.');
        setReturnto(null);
        setOpenToast(true);
        setRetryDelay(10);
        setFields(prev => ({ ...prev, userCaptcha: '' }));

        setTimeout(() => {
          generateCaptcha();
          setRetryDelay(0);
        }, 10000);

        return;
      }
      try {
        const data = `{"email": "${fields.email}",
                    "firstName": "${fields.firstName}",
                    "lastName": "${fields.lastName}",
                    "businessType": "${fields.businessType}",
                    "businessName": "${fields.businessName}",
                    "msg": "${fields.msg.replace(/\n/g, '<br>')}"}`;

        const res = await apiService.bepartnermail(data);
        setError(false);
        setmsgToast(`${'Thank you for your interest.<br/>We will contact you soon.'}`)
        setReturnto('/');
        setOpenToast(true);

      } catch (error) {
        setError(true);
        setmsgToast(`${error.message + '<br/>Check the entered data.'}`);
        setReturnto(null);
        setOpenToast(true);
      }
    }
  };

  const handleChange = (e, field) => {
    setFields(prev => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    if (returnto !== null) {
      navigate(returnto);
    }
  };

  return (
    <>
      <div className="home-container">
        <NavBar />
        <div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerBackground} alt="" />
          </div>
          <div className="home-text-section">
            <h1 className="primary-heading">Be Our Partner</h1>
            <p className="primary-text">Please fill out the following information</p>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={fields.email}
                    onChange={e => handleChange(e, 'email')}
                    margin="normal"
                    required
                    type='email'
                  />
                  {emailError && <small className="text-danger">{emailError}</small>}

                  <TextField
                    fullWidth
                    label="First Name"
                    value={fields.firstName}
                    onChange={e => handleChange(e, 'firstName')}
                    margin="normal"
                    required
                  />

                  <TextField
                    fullWidth
                    label="Last Name"
                    value={fields.lastName}
                    onChange={e => handleChange(e, 'lastName')}
                    margin="normal"
                    required
                  />

                  <TextField
                    fullWidth
                    label="Business Name"
                    value={fields.businessName}
                    onChange={e => handleChange(e, 'businessName')}
                    margin="normal"
                    required
                  />

                  <TextField
                    fullWidth
                    label="Business Type"
                    value={fields.businessType}
                    onChange={e => handleChange(e, 'businessType')}
                    margin="normal"
                    required
                  />

                  <TextField
                    fullWidth
                    label="Message"
                    value={fields.msg}
                    onChange={e => handleChange(e, 'msg')}
                    margin="normal"
                    required
                    multiline
                    minRows={2}
                    maxRows={2}
                  />

                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px', position: 'relative' }}>

                    <div style={{
                      backgroundColor: fields.captcha,
                      width: '30px',
                      height: '30px',
                      borderRadius: '5px',
                      position: 'absolute',
                      top: captchaPosition.top,
                      left: captchaPosition.left,
                      transition: 'top 0.3s, left 0.3s' // Smooth transition for visual effect
                    }}></div>
                    <Autocomplete
                      disablePortal
                      options={colors}

                      value={fields.userCaptcha}
                      onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'userCaptcha')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="What color is the square?"
                          margin="normal"
                          required
                          sx={{ mb: 1, width: '260px' }}
                          disabled={retryDelay > 0}
                        />
                      )}
                    />
                  </Box>

                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='contained' type="submit" sx={{ mt: 2 }} style={{ align: "center", backgroundColor: "#1789C6", height: "60px", width: "150px", color: "white", borderBlockColor: "#1789C6", borderBlock: "0px", fontWeight: 'bold', margin: "1.5rem", borderRadius: "50px" }}>
                      Submit
                    </Button>
                  </div>
                </form>
              </Box>
            </Box>
          </div>
          <div className="home-image-section">
            <img src={BannerImage} alt="" />
          </div>
        </div>
      </div>
      <FooterBar />
      <Toast open={openToast} handleClose={handleCloseToast}
        message={msgToast}
        error={error}
      />
    </>
  );
};

export default BePartner;
