import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Registro dos elementos do ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

// Plugin para exibir o valor central e o marcador no gráfico
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: (chart) => {
    const { width, height } = chart;
    const ctx = chart.ctx;
    const muscleScore = chart.config.options.plugins.centerText.muscleValue || '';
    const labelText = chart.config.options.plugins.centerText.label || 'Muscle Score';
    const activeIndex = chart.config.options.plugins.centerText.activeIndex;

    // --- Texto Central (Valor do Muscle Score) ---
    ctx.save();
    ctx.font = 'bold 24px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#84BBCE';
    ctx.fillText(muscleScore, width / 2, height / 2 - 25);

    // --- Label Abaixo do Valor ---
    ctx.font = '15px Times New Roman';
    ctx.fillStyle = '#6FA3B5';
    ctx.fillText(labelText, width / 2, height / 2 - 3);
    ctx.restore();

    // --- Triângulo Marcador ---
    const dataset = chart.config.data.datasets[0];
    const totalSectors = dataset.data.length;
    const angle = ((activeIndex + 0.5) / totalSectors) * Math.PI * 2 - Math.PI / 2;
    const outerRadius = chart.outerRadius + 15;
    const x = width / 2 + Math.cos(angle) * outerRadius;
    const y = height / 2 + Math.sin(angle) * outerRadius;

    ctx.save();
    ctx.fillStyle = '#A9A9A9';
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - 10 * Math.cos(angle - Math.PI / 6), y - 10 * Math.sin(angle - Math.PI / 6));
    ctx.lineTo(x - 10 * Math.cos(angle + Math.PI / 6), y - 10 * Math.sin(angle + Math.PI / 6));
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  },
};

const MusclePieChart = ({ muscleValue, sex }) => {
  const getZone = (value, sex) => {
    if (sex === 'M') {
      if (value <= 0.446209672) return 0;
      if (value <= 0.535210518) return 1;
      if (value <= 0.624211365) return 2;
      return 3;
    }
    if (sex === 'F') {
      if (value <= 0.363792262) return 0;
      if (value <= 0.48164322) return 1;
      if (value <= 0.599494178) return 2;
      return 3;
    }
    return -1;
  };

  const zoneIndex = getZone(muscleValue, sex);

  // 🎨 Cores mais saturadas
  const saturatedColors = ['#FF7B7B', '#FFD966', '#6FCF63', '#4CAF50'];

  // 🎨 Cores opacas para os setores inativos
  const fadedColors = saturatedColors.map((color, index) =>
    index === zoneIndex ? color : `${color}4D`
  );

  const zoneLabels = ['Low Zone', 'Intermediate Zone', 'Good Zone', 'Excellent Zone'];
  const percentiles = ['0-25th percentile', '25-50th percentile', '50-75th percentile', '75-100th percentile'];

  const data = {
    labels: ['Low', 'Intermediate', 'Good', 'Excellent'],
    datasets: [
      {
        data: [1, 1, 1, 1],
        backgroundColor: fadedColors,           // 🌈 Setor ativo com cor saturada
        hoverBackgroundColor: fadedColors,      // 🌈 Hover mantém a cor saturada
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '50%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          boxWidth: 11,
          padding: 8,
        },
      },
      centerText: {
        muscleValue: muscleValue.toFixed(2),
        label: 'Muscle Score',
        activeIndex: zoneIndex,
      },
    },
  };

  return (
    <div style={{ textAlign: 'center', width: '280px', height: '300px' }}>
      <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
      
      {/* Texto Abaixo da Legenda */}
      <div style={{ marginTop: '15px' }}>
        <p style={{ margin: 0, fontSize: '12px', color: saturatedColors[zoneIndex], fontWeight: 'bold' }}>
          Your Muscle Score is in the {zoneLabels[zoneIndex]}
        </p>
        <p style={{ margin: 0, fontSize: '10px', color: '#555' }}>
          Your Muscle Health Score falls within the {percentiles[zoneIndex]}<br />
          relative to other test takers of the same age and sex.
        </p>
      </div>
    </div>
  );
};

export default MusclePieChart;
