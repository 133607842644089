import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  FormHelperText,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import FooterBar from "../shared/footer";
import Toast from "../shared/toast";
import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../services/authService";
import { apiService } from "../services/apiService";
import BannerBackground from "../assets/login-background.png";
import Logo from "../assets/login-logo.png";
import "./LoginPage.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import urlHomeBase from "../services/urlBase";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password2Error, setPassword2Error] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { usertoken } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    if (!usertoken) {
      const storeduser = authService.getUserData();
      setEmail(storeduser.email);
    }
    else {
      const response = await authService.getDatabyToken(usertoken);
      setEmail(response.data.email);
    }

  };

  const validatePassword = (newPassword) => {
    if (newPassword.length < 8) {
      return "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(newPassword)) {
      return "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(newPassword)) {
      return "Password must contain at least one lowercase letter";
    } else if (!/\d/.test(newPassword)) {
      return "Password must contain at least one digit";
    } else {
      return "";
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const passError = validatePassword(password);
    setPasswordError(passError);

    if (passError === "") {
      if (password !== password2) {
        setPassword2Error("* Passwords don't match");
      } else {
        setPassword2Error("");
        setSubmitted(true);
        try {
          await apiService.newpassword({ email, oldpassword, password, token: usertoken });
          setMsgToast("Your password was changed. Now you can log in.");
          setError(false);
          authService.logOut();
          navigate("/login");
        } catch (error) {
          setMsgToast(error.response.data);
          setOldPassword("");
          setError(true);
          setSubmitted(false);
        }
        setOpenToast(true);
      }
    }
  };

const handleCancel = () => {
  const user = authService.getUserData();
  const userbaseUrl = urlHomeBase[user.role];
  navigate(userbaseUrl);
};

  const handleCloseToast = () => {
    setOpenToast(false);

    if (!error) {
      authService.logOut();
    }
  };

  return (
    <>
      <div
        className="login"
        style={{ backgroundImage: `url(${BannerBackground})` }}
      >
        <div className="login__container">
          <div className="login__card">
            <div className="login__logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="login__header">
              <h1 className="login__title"> {usertoken ? "Reset Password": "Change Password"}</h1>
            </div>
            <form onSubmit={submitForm} className="login__form">
              <TextField
                fullWidth
                label="Email (You can't change this)"
                value={email}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              {!usertoken && ( 

            <TextField
              fullWidth
              label="Old_Password"
              type={showPassword ? "text" : "password"}
              value={oldpassword}
              onChange={(e) => setOldPassword(e.target.value)}
              variant="filled"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
              
              
              
              )}
            <TextField
              fullWidth
              label="New Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
              {passwordError && (
                <FormHelperText error>{passwordError}</FormHelperText>
              )}

            <TextField
              fullWidth
              label="Retype New Password"
              type={showPassword ? "text" : "password"}
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              variant="filled"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
              
              {password2Error && (
                <FormHelperText error>{password2Error}</FormHelperText>
              )}
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 2                
              }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                  sx={{
                    width: "100px",
                    backgroundColor: "#156B79",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "50px",
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    width: "100px",
                    borderColor: "#156B79",
                    color: "#156B79",
                    fontWeight: "bold",
                    borderRadius: "50px",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </div>
        </div>
        <div className="login__footer">
          <p className="text-item login__footer__title">
            Myomar Molecular Inc.
          </p>
          <p className="text-item">+1 902-483-6644</p>
          <p className="text-item">support@myomarmolecular.com</p>
        </div>
      </div>

      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        message={msgToast}
        error={error}
      />
    </>
  );
};

export default ResetPassword;
