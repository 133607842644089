import React, { createContext, useState, useEffect } from 'react';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import LogoImage from "../assets/logo_partner.png";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');
  const [logo, setLogo] = useState(LogoImage);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const fetchPartners = async () => {
    const isLoggedIn = await authService.isLoggedIn();
      if (isLoggedIn) {
          const partnersResponse = await apiService.partnerByUserId();
          if (partnersResponse.data.length > 0) {
            setPartners(partnersResponse.data);
    
            const storedPartner = sessionStorage.getItem('selectedPartner') || partnersResponse.data[0].id;
            await handleSelectPartner(storedPartner); // Selecionar o parceiro inicial
        }
      }
  };

  const handleSelectPartner = async (id) => {
    const partnerData = await apiService.partnerbyid(id);
    setSelectedPartner(partnerData.data.name);
    setLogo(partnerData.data.logo ? `data:image/png;base64,${partnerData.data.logo}` : LogoImage);
    
    sessionStorage.setItem('userpartnerid', partnerData.data?.id || '');
    sessionStorage.setItem('userpartner', partnerData.data?.name || '');
    sessionStorage.setItem('logo', partnerData.data?.logo || LogoImage);
    sessionStorage.setItem('selectedPartner', id);
    
  };

  // Função para atualizar manualmente os dados da Sidebar
  const refreshSidebarData = async () => {
    await fetchPartners(); // Recarrega parceiros e dados relacionados
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isCollapsed,
        toggleSidebar,
        partners,
        selectedPartner,
        setSelectedPartner: handleSelectPartner,
        logo,
        setLogo,
        refreshSidebarData, // Exponha a função no contexto
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
