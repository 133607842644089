import React, { useState, useEffect } from "react";
import NavBar from "../shared/navBar";
import SideBar from "../shared/sideBar";
import { Container, Grid, Button, Box, Paper, Typography, Dialog, TextField, DialogActions, DialogContent, DialogTitle, Pagination} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Toast from "../shared/toast";
import TestKitDialog from "./DialogTestActivate";
import { apiService } from "../services/apiService";
import { authService } from "../services/authService";
import { formatDate } from '../shared/formatDate';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import dayjs from 'dayjs';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";
import { FaPlusSquare } from "react-icons/fa";
import { FaGift } from "react-icons/fa";
import { validator } from "../shared/validator";

const ClientActivateTest = () => {
  const [lstClients, setLstClients] = useState([]);
  const [openTestKitDialog, setOpenTestKitDialog] = useState(false);
  const [openGiftDialog, setOpenGiftDialog] = useState(false);
  const [giftFields, setGiftFields] = useState({ firstName:"", lastName:"", email: "", testKitId: "" });
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [emailError, setEmailError] = useState('');

  const fetchData = async () => {
    try {
      const userData = await authService.getUserData();
      const response = await apiService.getTestResultsByUserId(userData.id);
  
      if (Array.isArray(response.data)) {
        const clientlst = response.data.map((test) => ({
          id: test.id || 0,
          userId: test.user?.id || 0,
          firstName: test.user?.firstName || '',
          lastName: test.user?.lastName || '',
          activated: test.activated || '',
          labReceived: test.labReceived || '',
          testKitId: test.testKitId || '',
          testUnfinished: test.unfinished || false,
          expectedDropOff: formatDate(test.expectedDropOff, false) || '',
        }));
        setLstClients(clientlst);
      }
    } catch (error) {
      console.error("Error fetching test results", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { field: "testKitId", headerName: "Test Kit ID", flex: 1 },
    { field: "expectedDropOff", headerName: "Expected Drop Off", flex: 1 },
    {
      field: "labReceived",
      headerName: "Lab Received",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Tooltip title={`Lab received on: ${params.value}`}>
            <CheckIcon style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <CloseIcon style={{ color: "gray" }} />
        ),
    },
    {
      field: "edit",
      headerName: "Edit Test Kit",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="Edit Test Kit">
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleEditTestKit(params.row)}
          >
            <QrCodeScanner />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const handleCloseTestKitDialog = () => {
    setOpenTestKitDialog(false);
    setGiftFields({ email: '', testKitId: '' }); // Limpa os campos
  };

  const handleFieldChange = (e, field) => {
    setGiftFields((prev) => ({ ...prev, [field]: e.target.value }));
  };
  
  const handleDateChange = (date) => {
    setGiftFields((prev) => ({ ...prev, expectedDropOff: date }));
  };

  const validateFields = () => {
    return {
      testKitId: !giftFields.testKitId && 'Test Kit ID is required',
      expectedDropOff: !giftFields.expectedDropOff && 'Expected Drop Off date is required',
    };
  };

  const handleActivateTestKit = async () => {
    const errors = validateFields();
    if (errors.testKitId || errors.expectedDropOff) {
      setMsgToast('Please fill in all required fields');
      setIsError(true);
      setOpenToast(true);
      return;
    }
  
    try {
      const userData = await authService.getUserData();
      if (giftFields.id > 0) {
          const testResult = await apiService.getTestResultById(giftFields.id);
          testResult.data.expectedDropOff = giftFields.expectedDropOff;
          testResult.data.testKitId = giftFields.testKitId;
          await apiService.updateTestResult(testResult.data);
          setMsgToast('Kit updated.');
      } else {
          const newTestResult = {
            userId: userData.id, // ID do usuário logado
            testKitId: giftFields.testKitId,
            expectedDropOff: giftFields.expectedDropOff,
          };
          await apiService.activatetest(newTestResult);
          setMsgToast('Test Kit activated successfully!');
      }
      setIsError(false);
      setOpenToast(true);
      handleCloseTestKitDialog(); // Fecha o diálogo e limpa os campos
      await fetchData(); // Atualiza os dados do grid
    } catch (error) {
      setMsgToast(error.response?.data || 'An error occurred during activation');
      setIsError(true);
      setOpenToast(true);
    }
  };
  

  const handleAddTestKit = () => {
    setGiftFields({expectedDropOff: dayjs().add(6, 'day')}); // Limpa os campos
    setOpenTestKitDialog(true);
  };

  const handleGiftTestKit = () => {
    setOpenGiftDialog(true);
  };

  const handleCloseGiftDialog = () => {
    setOpenGiftDialog(false);
    setGiftFields({ email: "", testKitId: "" });
  };

  const handleGiftSend = async () => {
    setEmailError(validator.validateEmail(giftFields.email));
    if (emailError === '') {
      if (!giftFields.email || !giftFields.testKitId) {
        setMsgToast("Both fields are required");
        setIsError(true);
        setOpenToast(true);
        return;
      }
      try {
        await apiService.giftTestKit(giftFields.email, giftFields.testKitId);
        setMsgToast("Gift Test Kit sent successfully");
        setIsError(false);
        setOpenToast(true);
        handleCloseGiftDialog();
        await fetchData(); // Atualiza os dados do grid
      } catch (error) {
        setMsgToast(error.response?.data || "An error occurred");
        setIsError(true);
        setOpenToast(true);
      }
    }
  };

  const handleEditTestKit = (row) => {
    setGiftFields({
      id: row.id,
      firstName: row.firstName,
      lastName: row.lastName,
      email: '', // Opcional, caso necessário para outra lógica
      testKitId: row.testKitId,
      expectedDropOff: row.expectedDropOff ? dayjs(row.expectedDropOff) : null, 
    });
    setOpenTestKitDialog(true);
  };

  return (
    <>
      <Header />
      <div className="bgColor">
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: 1, height: "100vh", overflow: "auto", p: 3 }}>
            <NavInfo
              currentLinkText="Test Kits"
              title="Test Kits"
              canShowButton={false}
            />
            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <div className="navInfo__button">
                      <Button
                        variant="primary"
                        sx={{
                          background: "#156B79",

                          "&:hover": {
                            background: "#156B79",
                          },
                        }}
                        onClick={handleAddTestKit}
                      >
                        <FaPlusSquare />
                        <span>Add Test Kit</span>
                      </Button>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="navInfo__button">
                      <Button
                        variant="primary"
                        sx={{
                          background: "#156B79",

                          "&:hover": {
                            background: "#156B79",
                          },
                        }}
                        onClick={handleGiftTestKit}
                      >
                        <FaGift />
                        <span>Gift Test Kit</span>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <DataGrid
                    rows={lstClients}
                    columns={columns.map((column) => ({
                      ...column,
                      flex: 1, // Allow columns to auto-size based on content
                    }))}
                    onPageChange={(newPage) => setPage(newPage)}
                    initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      disableSelectionOnClick // Disables row selection on click
                      checkboxSelection={false} // Removes the checkbox column
                      components={{
                        Pagination: () => (
                          <Pagination
                            count={Math.ceil(lstClients.length / 5)}
                            shape="rounded"
                            hidePrevButton={lstClients.length <= 5} // Hide previous button if only one page
                            hideNextButton={lstClients.length <= 5} // Hide next button if only one page
                          />
                        ),
                      }}
                    autoHeight
                    sx={{
                      backgroundColor: "#ffffff",
                      "& .MuiDataGrid-columnHeaders": {
                        fontWeight: "bold", // Bold headers
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold", // Ensure bold text in headers
                      },
                      "& .MuiTablePagination-displayedRows": {
                        display: "none", // Hide the displayed rows text
                      },
                      "& .MuiTablePagination-selectLabel": {
                        display: "none", // Hide "Rows per page" text
                      },
                      "& .MuiInputBase-root": {
                        display: "none", // Hide dropdown for rows per page
                      },
                    }}
                  />
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>

      <TestKitDialog
        open={openTestKitDialog}
          handleClose={handleCloseTestKitDialog}
          handleActivate={handleActivateTestKit}
          fields={giftFields}
          handleChange={handleFieldChange}
          handleDateChange={handleDateChange}
          errors={validateFields()}
        />


      <Dialog open={openGiftDialog} onClose={handleCloseGiftDialog}>
        <DialogTitle>Gift Test Kit</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={giftFields.email}
            onChange={(e) => setGiftFields((prev) => ({ ...prev, email: e.target.value }))}
          />
          {emailError && (
               <small className="text-danger">{emailError}</small>
          )}
          <TextField
            fullWidth
            margin="normal"
            label="Test Kit ID"
            value={giftFields.testKitId}
            onChange={(e) => setGiftFields((prev) => ({ ...prev, testKitId: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <div className="navInfo__button">
            <Button
              variant="primary"
              sx={{
                background: "#156B79",

                "&:hover": {
                  background: "#156B79",
                },
              }}
              onClick={handleCloseGiftDialog}
            >
              <span>Cancel</span>
            </Button>
          </div>
          <div className="navInfo__button">
            <Button
              variant="primary"
              sx={{
                background: "#156B79",

                "&:hover": {
                  background: "#156B79",
                },
              }}
              onClick={handleGiftSend}
            >
              <span>Send</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Toast open={openToast} handleClose={() => setOpenToast(false)} message={msgToast} error={isError} />
    </>
  );
};

export default ClientActivateTest;
