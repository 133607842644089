import React, { useContext, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { Box, FormControl, Select, MenuItem, Menu } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { QuizOutlined } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import CompanyLogo from "../assets/company-logo.png";
import { ReactComponent as UsersCheck } from "../assets/icons/Users/users-check.svg";
import { ReactComponent as UserUp2 } from "../assets/icons/Users/user-up-02.svg";
import { ReactComponent as UsersMinus } from "../assets/icons/Users/users-minus.svg";
import { ReactComponent as Glasses2 } from "../assets/icons/Education/glasses-02.svg";
import { ReactComponent as CheckDone2 } from "../assets/icons/General/check-done-02.svg";
import { ReactComponent as ClockSnooze } from "../assets/icons/Time/clock-snooze.svg";
import { ReactComponent as InfoHexagon } from "../assets/icons/General/info-hexagon.svg";
import LogoImage from "../assets/logo_partner.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../context/SidebarContext";
import { authService } from "../services/authService";
import { apiService } from '../services/apiService';
import {
  AccountBox,
  Biotech,
  FolderShared,
  Groups,
  ViewList,
} from "@mui/icons-material";
import { HiChevronDown } from "react-icons/hi";

import "./sideBar.scss";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = () => {
  const {
    isCollapsed,
    toggleSidebar,
    partners,
    selectedPartner,
    setSelectedPartner,
    logo,
  } = useContext(SidebarContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const userData = authService.getUserData();
  const [userRole, setUserRole] = useState();
  const [shouldReload, setShouldReload] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null); // Gerencia o dropdown

  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData() {
    const userdata = await authService.getUserData();
    setUserRole(userdata.role);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Abre o menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Fecha o menu
  };

  // Monitorar alterações em selectedPartner e navegar apenas uma vez
  useEffect(() => {
    if (shouldReload) {
      navigate(0); // Recarrega a página
      setShouldReload(false); // Reseta o controle para evitar loops
    }
  }, [selectedPartner]);

  const handlePartnerChange = async (partnerId) => {
    setSelectedPartner(partnerId); // Atualiza o parceiro selecionado
    setShouldReload(true); // Ativa o redirecionamento
    handleClose(); // Fecha o menu
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      toggleSidebar(false); // Fecha a sidebar ao mudar de página no mobile
    }
  }, [location.pathname]);

  //usado por outras páginas para zerar session on load
  sessionStorage.setItem("searchQuery", "");
  sessionStorage.setItem("showOnlyActive", "true");
  sessionStorage.setItem("page", "0");

  const { role } = userData;

  const renderListItem = (
    role,
    requiredRoles,
    text,
    icon,
    href = "/teste",
    onClick
  ) => {
    const currentPath = window.location.hash;
    const isActive = currentPath.includes(href);

    if (requiredRoles.includes(role)) {
      return (
        <ListItem
          disablePadding
          sx={{
            display: "block",
            height: 45,
            padding: "0 8px",
          }}
          onClick={() => navigate(href)}
        >
          <ListItemButton
            sx={{
              justifyContent: isCollapsed ? "initial" : "center",
              px: 2.5,
              backgroundColor: isActive && "#156B79",
              borderRadius: "4px",
              color: isActive && "#fff",
              "&:hover": {
                backgroundColor: "#156B79 !important",
                color: "#fff !important",

                "& path": {
                  stroke: "#fff !important",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isCollapsed ? "8px" : "auto",
                justifyContent: "center",
              }}
            >
              <div className={`sidebar__icon ${isActive && "--active"}`}>
                {icon}
              </div>
            </ListItemIcon>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ fontSize: "14px", fontWeight: "400" }}
              sx={{ opacity: isCollapsed ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: isMobile ? "100%" : !isCollapsed ? "0 !important" : "252px !important",
        marginLeft: isMobile
          ? (isCollapsed ? "-252px" : "0px")  // Mobile: Esconder quando fechado
          : (isCollapsed ? "0px" : "252px"), // Desktop: Empurrar conteúdo ao abrir
        transition: "margin-left 0.3s ease-in-out", // Animação suave ao abrir/fechar
      }}
    >
      <CssBaseline />
      <Drawer
        variant={isMobile ? "temporary" : "permanent"} // Mobile: Sobrepor | Desktop: Empurrar
        anchor="left"
        open={isCollapsed}
        onClose={toggleSidebar} // Permite fechar ao clicar fora em mobile
        PaperProps={{
          sx: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            mt: "64px",
            width: "252px",
            "box-shadow": "0px 2px 1px -1px #00000033",
          },
        }}
        ModalProps={{
          keepMounted: true,
          BackdropProps: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }, // Fundo escuro no mobile
        }}
      >

        {((userRole === "Partner" || userRole === "AuthorizedUser") && partners.length > 0) && (
          <div className="sidebar__company">
            <div className="sidebar__company__info" onClick={handleClick} style={{ cursor: "pointer", flexDirection: "column", alignItems: "center" }}>
              <img src={logo} alt="Company Logo" style={{ marginBottom: "8px" }} />
              <p>
                <span className="--title">{selectedPartner || "Select a Partner"}</span>
              </p>
              <HiChevronDown />
            </div>


            {/* Dropdown usando o componente Menu do Material-UI */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 300,
                  width: 250,
                },
              }}
            >
              {partners.map((partner) => (
                <MenuItem
                  key={partner.id}
                  onClick={() => handlePartnerChange(partner.id)}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "16.41px",
                    color: "#2f2f2f",
                  }}
                >
                  {partner.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        <Divider />

        <p className="sidebar__indicator">NAVIGATION</p>
        <List
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#000000",
            display: !isCollapsed && "none",
          }}
        >
          {renderListItem(
            role,
            ["Client"],
            "Welcome",
            <GroupsIcon style={{ color: "#23333F" }} />,
            "/clientdash",
            () => navigate("/clientdash")
          )}
          {renderListItem(
            role,
            ["Admin"],
            "Dashboard",
            <GroupsIcon style={{ color: "#23333F" }} />,
            "/dashboard",
            () => navigate("/dashboard")
          )}
          {renderListItem(
            role,
            ["Admin"],
            "Manage Partner",
            <UsersCheck style={{ color: "#23333F" }} />,
            "/managepartner",
            () => navigate("/managepartner")
          )}
          {renderListItem(
            role,
            ["Admin", "Partner", "AuthorizedUser", "Lab_Manager"],
            "Manage Clients",
            <UserUp2 style={{ color: "#23333F" }} />,
            "/manageclient",
            () => navigate("/manageclient")
          )}
          {renderListItem(
            role,
            ["Admin", "Partner"],
            "Manage Users",
            <UsersMinus style={{ color: "#23333F" }} />,
            "/manageuser",
            () => navigate("/manageuser")
          )}

          {renderListItem(
            role,
            ["Admin", "Lab_Manager"],
            "Quick Activate Test Kits",
            <FactCheckOutlinedIcon style={{ color: "#23333F" }} />,
            "/quickactivate",
            () => navigate("/quickactivate")
          )}

          {renderListItem(
            role,
            ["Admin", "Partner", "AuthorizedUser", "Lab_Manager"],
            "Test Kits",
            <FactCheckOutlinedIcon style={{ color: "#23333F" }} />,
            "/testActivate",
            () => navigate("/testActivate")
          )}

          {renderListItem(
            role,
            ["Admin", "Partner", "AuthorizedUser"],
            "Test Progress",
            <AutoGraphOutlinedIcon style={{ color: "#23333F" }} />,
            "/testsProgress",
            () => navigate("/testsProgress")
          )}

          {renderListItem(
            role,
            ["Client"],
            "Test Kits",
            <FactCheckOutlinedIcon style={{ color: "#23333F" }} />,
            "/clientactivatetest",
            () => navigate("/clientactivatetest")
          )}
          {renderListItem(
            role,
            ["Lab_Manager"],
            "Kits Receipt",
            <Biotech style={{ color: "#23333F" }} />,
            "/labreceipt",
            () => navigate("/labreceipt")
          )}
          {renderListItem(
            role,
            ["Admin"],
            "Test Score",
            <InsertChartOutlinedIcon style={{ color: "#23333F" }} />,
            "/testscore",
            () => navigate("/testscore")
          )}
          {renderListItem(
            role,
            ["Admin", "Lab_Manager"],
            "Test Result",
            <CheckDone2 style={{ color: "#23333F" }} />,
            "/managetestresults",
            () => navigate("/managetestresults")
          )}
          {renderListItem(
            role,
            ["Client", "Partner", "AuthorizedUser"],
            "Questionnaire",
            <QuizOutlined style={{ color: "#23333F" }} />,
            "/questionnaire",
            () => navigate("/questionnaire")
          )}
          {renderListItem(
            role,
            ["Partner"],
            "Your Business",
            <CorporateFareOutlinedIcon style={{ color: "#23333F" }} />,
            "/partnerinfo",
            () => navigate("/partnerinfo")
          )}
          {renderListItem(
            role,
            ["Client"],
            "Test Report",
            <CheckDone2 style={{ color: "#23333F" }} />,
            "/clienttestreport",
            () => navigate("/clienttestreport")
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default SideBar;