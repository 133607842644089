import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Stack,
  FormHelperText,
} from "@mui/material";
import Toast from "../shared/toast";
import FooterBar from "../shared/footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { authService } from "../services/authService";
import { apiService } from "../services/apiService";
import BannerBackground from "../assets/login-background.png";
import Logo from "../assets/login-logo.png";
import "./LoginPage.scss";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [testKitId, setTestKitId] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password2Error, setPassword2Error] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const emailParam = searchParams.get("email");
    const testKitIdParam = searchParams.get("testkitid");
    if (emailParam) setEmail(emailParam);
    if (testKitIdParam) setTestKitId(testKitIdParam);
  }, [searchParams]);

  const validatePassword = (newPassword) => {
    if (newPassword.length < 8) {
      return "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(newPassword)) {
      return "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(newPassword)) {
      return "Password must contain at least one lowercase letter";
    } else if (!/\d/.test(newPassword)) {
      return "Password must contain at least one digit";
    } else {
      return "";
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const passError = validatePassword(password);
    setPasswordError(passError);

    if (passError === "") {
      if (password !== password2) {
        setPassword2Error("* Passwords don´t match");
      } else {
        setPassword2Error("");
        setSubmitted(true);
        try {
          const newUser = await apiService.addClient({
            email,
            password,
            role: 4,
          });

          const testResult = {
            userId: newUser.data.id,
            testKitId,
            expectedDropOff: dayjs().add(6, "day").toISOString(),
          };

          const userData = { email: newUser.data.email, password };

          const apiLogin = await authService.login(userData, false);

          await apiService.activatetest(testResult);

          setMsgToast("Sign-up successful! Now you can log in.");
          setError(false);
          navigate("/login");
        } catch (err) {
          setMsgToast(
            "An error occurred during sign-up. Please try again."
          );
          setError(true);
          setSubmitted(false);
        }
        setOpenToast(true);
      }
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  return (
    <>
      <div
        className="login"
        style={{ backgroundImage: `url(${BannerBackground})` }}
      >
        <div className="login__container">
          <div className="login__card">
            <div className="login__logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="login__header">
              <h1 className="login__title">Sign Up</h1>
              <p className="login__subtitle">
                Create your account to start using the platform
              </p>
            </div>
            <form onSubmit={submitForm} className="login__form">
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                type="email"
                inputProps={{ autoComplete: 'new-email' }}
                required
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                inputProps={{ autoComplete: 'new-password' }}
                required
              />
              {passwordError && (
                <FormHelperText error>{passwordError}</FormHelperText>
              )}
              <TextField
                fullWidth
                label="Retype Password"
                type="password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                margin="normal"
                inputProps={{ autoComplete: 'new-password2' }}
                required
              />
              {password2Error && (
                <FormHelperText error>{password2Error}</FormHelperText>
              )}
              <TextField
                fullWidth
                label="Confirm your TestKitId"
                type="text"
                value={testKitId}
                onChange={(e) => setTestKitId(e.target.value)}
                margin="normal"
                autoComplete="off"
                required
              />
              <Stack spacing={2} mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                  sx={{
                    backgroundColor: "#156B79",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "50px",
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/login")}
                  sx={{
                    borderColor: "#156B79",
                    color: "#156B79",
                    fontWeight: "bold",
                    borderRadius: "50px",
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          </div>
        </div>
        <FooterBar />
      </div>
      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        message={msgToast}
        error={error}
      />
    </>
  );
};

export default SignUp;
