import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { authService } from '../services/authService';

const DialogUser = ({ fields, programTypes, clientPartners, handleChange, errors }) => {
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        if (programTypes.length > 0 && !fields.programTypeId) {
            handleChange({ target: { value: programTypes[0].id } }, 'programTypeId');
        }
    }, [programTypes, fields.programTypeId]);

    useEffect(() => {
        const storeduser = authService.getUserData();
        setUserRole(storeduser.role);
    }, [userRole]);
    return (
        <>
            <TextField
                fullWidth
                label="First Name"
                value={fields.firstName}
                onChange={e => handleChange(e, 'firstName')}
                margin="normal"
                required
                autoComplete="off"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
            />
            <TextField
                fullWidth
                label="Last Name"
                value={fields.lastName}
                onChange={e => handleChange(e, 'lastName')}
                margin="normal"
                required
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
            />
            <div style={{ height: '75px', marginTop: '20px' }}>
                <FormControl fullWidth variant="outlined" error={!!errors.sexAtBirth}>
                    <InputLabel id='lbsex'>Sex At Birth</InputLabel>
                    <Select fullWidth label="Sex At Birth" value={fields.sexAtBirth} onChange={e => handleChange(e, 'sexAtBirth')} required>
                        <MenuItem value={"M"}>Male</MenuItem>
                        <MenuItem value={"F"}>Female</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <TextField
                fullWidth
                label={"Email"}
                value={fields.email}
                onChange={e => handleChange(e, 'email')}
                margin="normal"
                required
                error={Boolean(errors.email)}
                helperText={errors.email}
            />
            <TextField
                fullWidth
                label="Mobile"
                value={fields.mobile}
                onChange={e => handleChange(e, 'mobile')}
                margin="normal"
            />
            <div style={{ alignItems: 'center', marginTop: '20px' }}>

                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" error={!!errors.programTypeId}>
                        <InputLabel>Program Type</InputLabel>
                        <Select
                            value={fields.programTypeId}
                            onChange={(e) => handleChange(e, 'programTypeId')}
                            label="Program Type"
                        >
                            {programTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {fields.id > 0 && clientPartners && (
                    <div style={{ height: '75px', marginTop: '20px' }}>
                        <FormControl>
                            <InputLabel>Partner</InputLabel>
                            <Select
                                value={fields.partnerId}
                                onChange={(e) => handleChange(e, 'partnerId')}
                                label="Partner"
                            >
                                {clientPartners?.map((partner) => (
                                    <MenuItem key={partner.id} value={partner.id} >
                                        {partner.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                <div style={{ height: '90px', marginTop: '20px'  }}>
                    {userRole === "Admin" && (
                        <FormControl component="fieldset" error={!!errors.role}>
                            <InputLabel id='lbrole'>Role</InputLabel>

                            <Select fullWidth label="Role" value={fields.role} onChange={e => handleChange(e, 'role')} required InputProps={{ readOnly: userRole !== "Admin" ? true : false }}
                                sx={{
                                    maxHeight: 300, // Increase the height of the dropdown
                                    width: 250, // Adjust the width of the dropdown
                                }}>
                                <MenuItem value={"1"}>Admin</MenuItem>
                                <MenuItem value={"3"}>Lab_Manager</MenuItem>
                                <MenuItem value={"4"}>Client</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </div>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fields.active}
                                    onChange={e => handleChange(e, 'active')}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label="User is active"
                        />
                    </FormControl>
                </Grid>
            </div>
        </>
    );
};

export default DialogUser;
