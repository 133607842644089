export const formatDate = (dateString, showTime = false) => {
    
    if(dateString === "" | dateString === null)
    {
        return "-";
    }
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);

    let formattedDate = date.toLocaleDateString('en-US', options);

    if (showTime) {
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        const fTime = date.toLocaleTimeString('en-US', timeOptions);
        formattedDate += ` - ${fTime}`;
    }

    return formattedDate;
};
