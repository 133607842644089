import React, { useState, useEffect } from 'react';
import { Box, Container, Paper, Typography, CircularProgress } from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import SideBar from "../shared/sideBar";
import Header from "../shared/header";
import NavInfo from "../shared/NavInfo";
import MusclePieChart from '../components/MusclePieChart';

function ClientDash() {
  const [latestScore, setLatestScore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Busca o último Muscle Score do usuário
  useEffect(() => {
    async function fetchLatestScore() {
      try {
        const userData = await authService.getUserData();
        const res = await apiService.getTestResultsByUserId(userData.id);

        if (res.data && res.data.length > 0 && res.data.muscle > 0) {
          // Busca o teste com o maior TestNumber
          const latestTest = res.data.reduce((max, current) => 
            current.testNumber > max.testNumber ? current : max
          );

          setLatestScore(latestTest);
        } else {
          setError(true);
        }
      } catch (err) {
        console.error("Failed to fetch muscle score:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetchLatestScore();
  }, []);

  return (
    <>
      <Header />
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            p: 3,
          }}>
            <NavInfo
              currentLinkText="Home"
              title="Home"
              canShowButton={false}
            />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 3, textAlign: 'center', minHeight: '500px'  }}>
                  {loading ? (
                    <CircularProgress />
                  ) : error ? (
                    <div style={{ marginTop: '50px' }}>
                        <p style={{ margin: 0, fontSize: '15px', color: '#555' }}>
                          Once your test is complete you will find your muscle score here.
                        </p>
                    </div>
                  ) : latestScore ? (
                    <>
                      <Typography variant="h5" sx={{ mb: 2 }}>
                        Latest Muscle Score
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <MusclePieChart
                          muscleValue={parseFloat(latestScore.muscle / 100)}
                          sex={latestScore.user.sexAtBirth}
                        />
                      </Box>
                    </>
                  ) : (
                    <Typography variant="h6">
                      Welcome!
                      You are logged In.
                    </Typography>
                  )}
                </Paper>
              </Container>

          </Box>
        </Box>
      </div>
    </>
  );
}

export default ClientDash;
