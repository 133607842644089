import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { List, ListItem, Container, Grid, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel, CssBaseline, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Typography } from '@mui/material';
import { apiService } from '../services/apiService';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditNote from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Toast from '../shared/toast';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Block from '@mui/icons-material/Block';
import { formatDate } from '../shared/formatDate';
import { Row } from 'antd';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const initialState = {
    id: 0,
    userid: 0,
    firstName: "",
    lastName: "",
    testKitId: "",
    activated: "",
    labReceived: "",
    finished: ""
};

const receiveState = {
    testKitId: "",
    Name: "",
    Partner: "",
    Status: ""
  };

const LabReceipt = () => {
    const [clienterror, setClienterror] = useState('');
    const navigate = useNavigate();
    const [lstClients, setLstClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('Kit Activated.');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchText, setSearchText] = useState('');
    const [refresh, setRefresh] = useState(false); // New state for data refresh
    const testKitIdRef = useRef(null);
    const [testKitList, setTestKitList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const clientsxresults = await apiService.getActiveNotFinished();
                if (Array.isArray(clientsxresults.data)) {
                    const clientlst = clientsxresults.data.map(test => ({
                        id: test.id || 0,
                        userId: test.user.id || 0,
                        firstName: test.user.firstName || '',
                        lastName: test.user.lastName || '',
                        activated: test.activated || '',
                        labReceived: test.labReceived || '',
                        testKitId: test.testKitId || '',
                        testNumber: test.testNumber  || '',
                    }));

                    setLstClients(clientlst);
                }
            } catch (error) {
                console.error('Error fetching kits.', error);
            }
        }

        fetchData();
    }, [refresh]); // Depend on refresh state

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                if (testKitIdRef.current) {
                    testKitIdRef.current.focus();
                }
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [open]);

    const handleChange = (e, field) => {
        setFields(prev => ({
            ...prev,
            [field]: e.target.value,
        }));
        setClienterror('');
    };

    const handleClose = () => {
        fields.testKitId = '';
        setOpen(false);
        setRefresh(!refresh);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleUpdateKit = async () => {
        try {
            if(fields.testKitId){

            const kitToReceive = await apiService.getByTestKitId(fields.testKitId);
            if (kitToReceive) {
                if (kitToReceive.data.labReceived) {
                    setmsgToast('Kit already received!');
                    setError(true);
                    setOpenToast(true);
                } else {
                    const receive = await apiService.receivetest(kitToReceive.data);
        
                    const newTestKit = {
                        Name: kitToReceive.data.user.firstName + ' ' + kitToReceive.data.user.lastName,
                        Partner: kitToReceive.data.Partner,
                        testKitId: kitToReceive.data.testKitId,
                        Status: receive.data
                    };
        
                    // Ensure prevList is always an array
                    setTestKitList(prevList => [...(prevList || []), newTestKit]);
        
                    //setFields({ testKitId: "" }); // Clear the input field
                    setError(false);
                }
            }
            fields.testKitId = '';
        }
        }
        catch (error) {
            if (error.response.data.title === 'Not Found') {
                setmsgToast('Kit identification not found');
            }
            else {
                setmsgToast('Failed to communicate with server.');
            }

            setError(true);
            setOpenToast(true);
        } finally {
            if (testKitIdRef.current) {
                testKitIdRef.current.focus();
            }
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchQuery(searchText);
        }
    };

    const filteredClients = lstClients.filter(client =>
        client.testKitId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.testKitId.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedClients = filteredClients.slice().sort((a, b) => {


        const compare = (propA, propB, isNumeric = false) => {
            if (propA !== undefined && propB !== undefined) {
                if (isNumeric) {
                    return order === 'asc' ? propA - propB : propB - propA;
                } else {
                    return order === 'asc' ? String(propA).localeCompare(String(propB)) : String(propB).localeCompare(String(propA));
                }
            }
            return 0;
        };

        if (orderBy === 'First Name') {
            return compare(a.firstName, b.firstName);
        }
        if (orderBy === 'Last Name') {
            return compare(a.lastName, b.lastName);
        }
        if (orderBy === 'Tests') {
            return compare(a.testNumber, b.testNumber);
        }
        if (orderBy === 'Activated') {
            return compare(a.activated, b.activated);
        }
        if (orderBy === 'Lab') {
            return compare(a.labReceived, b.labReceived);
        }
        if (orderBy === 'Test Kit') {
            return compare(a.testKitId, b.testKitId, true);
        }
        return 0;
    });

    const paginatedClients = sortedClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleCopyToClipboard = () => {
        const tableText = testKitList
            .map(kit => `${kit.testKitId}\t${kit.Name} ${kit.Status}`)
            .join('\n');
        navigator.clipboard.writeText(tableText)
            .then(() => {
                setmsgToast('Table content copied to clipboard!');
                setError(false);
                setOpenToast(true);
            })
            .catch(err => {
                setmsgToast('Failed to copy text: ', err);
                setError(true);
                setOpenToast(true);


            });

        if (testKitIdRef.current) {
            testKitIdRef.current.focus();
        }
    };

    return (
        <>
            <Header />
            <div className="bgColor">
                <Box sx={{ display: 'flex' }}>
                    <SideBar />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'visible',
                            p: 3,
                        }}
                    >
                    <NavInfo
                        currentLinkText="Test Kit Receipt"
                        title="Test Kit Receipt"
                        canShowButton={false}
                        />
                        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search for TestKits"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                            endAdornment: (
                                                <Button onClick={() => {
                                                    setSearchText('');
                                                    setSearchQuery('');
                                                }}>
                                                    <ClearIcon />
                                                </Button>
                                            ),
                                        }}
                                        sx={{
                                            '.MuiOutlinedInput-root': {
                                                borderRadius: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '.MuiInputAdornment-root': {
                                                margin: '0 4px',
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ marginTop: "10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setOpen(true)}
                                        sx={{
                                            width: "150px",
                                            backgroundColor: "rgba(197, 220, 255, 1)",
                                            color: "#000000",
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            borderColor: "rgba(197, 220, 255, 1)"
                                        }}
                                    >
                                        Receive kits
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {['First Name', 'Last Name', '#', 'Activated', 'Received in', 'Test Kit'].map((field) => (
                                                        <TableCell key={field} sortDirection={orderBy === field ? order : false} sx={{ fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                                            <TableSortLabel
                                                                active={orderBy === field}
                                                                direction={orderBy === field ? order : 'asc'}
                                                                onClick={(event) => handleRequestSort(event, field)}
                                                            >
                                                                {field.charAt(0).toUpperCase() + field.slice(1)}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {paginatedClients.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{row.firstName}</TableCell>
                                                        <TableCell>{row.lastName}</TableCell>
                                                        <TableCell>{row.testNumber}</TableCell>
                                                        <TableCell>{formatDate(row.activated, false)}</TableCell>
                                                        <TableCell>{formatDate(row.labReceived, false)}</TableCell>
                                                        <TableCell>{row.testKitId}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TablePagination
                                        component="div"
                                        count={sortedClients.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[]}  // Disable the rows per page options
                                        labelRowsPerPage=""       // Hide the "Rows per page" label
                                        sx={{
                                            '& .MuiTablePagination-selectLabel': {
                                                display: 'none',      // Hide the "Rows per page" text
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                                display: 'none',      // Hide the "6–10 of 22" text
                                            },
                                            '& .MuiInputBase-root': {
                                                display: 'none',      // Hide the rows per page dropdown
                                            },
                                        }}
                                    />

                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </div>

            <Dialog open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleClose();
                    }
                }}
                maxWidth="sm" fullWidth>
                <DialogTitle>
                    {fields.id > 0 ? "Update Test Kit" : "Receive Test Kit"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Test Kit Identification"
                                value={fields.testKitId}
                                onChange={(e) => handleChange(e, 'testKitId')}
                                fullWidth
                                error={!!errors.testKitId}
                                helperText={errors.testKitId}
                                inputRef={testKitIdRef}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleUpdateKit();
                                    }
                                }}
                            />
                        </Grid>

                        {testKitList.length > 0 && (
                            <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: '10px' }}>

                                <TableContainer sx={{ margin: '12px' }}>
                                    <Table sx={{ fontSize: '9px' }}>
                                        <TableHead>
                                            <TableRow sx={{ fontStyle: 'italic' }}>
                                                <TableCell sx={{ padding: '0px' }}>TestKit</TableCell>
                                                <TableCell sx={{ padding: '0px' }}>Client Name</TableCell>
                                                <TableCell sx={{ padding: '0px' }}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {testKitList.map((kit, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ padding: '0px' }}>{kit.testKitId}</TableCell>
                                                    <TableCell sx={{ padding: '0px' }}>{kit.Name}</TableCell>
                                                    <TableCell sx={{ padding: '0px' }}>{kit.Status}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Tooltip title="copy to clipboard">
                                    <Button
                                        style={{ width: 30, height: 30, paddingTop: 10 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCopyToClipboard}
                                    >
                                        <Box position="relative" display="inline-block">
                                            <ContentCopyIcon style={{ width: 24, height: 24 }} />
                                        </Box>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained'
                        type="button"
                        onClick={handleUpdateKit}
                        sx={{
                            width: "150px",
                            backgroundColor: "rgba(197, 220, 255, 1)",
                            color: "#000000",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            borderColor: "rgba(197, 220, 255, 1)"
                        }} >
                        {fields.Id > 0 ? "Updade" : "Receive"}
                    </Button>
                    <Button
                        variant='contained'
                        type="button"
                        onClick={handleClose}
                        sx={{
                            width: "150px",
                            backgroundColor: "rgba(197, 220, 255, 1)",
                            color: "#000000",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            borderColor: "rgba(197, 220, 255, 1)"
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default LabReceipt;
