import React from "react";
import Logo from "../assets/Logo.png";
import { FaXTwitter } from "react-icons/fa6";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import './footer.css';

function FooterBar() {
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className="footer-wrapper">
<div className="text-container" style={{ paddingRight: '150px', fontSize: '12px' }}>
      <div className="text-item">Myomar Molecular Inc.</div>
      <div className="text-item">+1 902-483-6644</div>
      <div className="text-item">support@myomarmolecular.com</div>
    </div>
    <div className="text-container" style={{ paddingRight: '150px', fontSize: '12px' }}>
      <div className="text-item"></div>
      <div className="text-item"></div>
      <div className="text-item"></div>
    </div>
    </div>

  );


}

export default FooterBar;
