import './PartnerInfo.css';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, InputLabel, MenuItem, Select, FormControl, FormControlLabel, Checkbox, Grid, Paper, Container } from "@mui/material";
import Toast from "../shared/toast";
import { apiService } from '../services/apiService';
import Autocomplete from '@mui/material/Autocomplete';
import SideBar from '../shared/sideBar';
import LogoImage from "../assets/logo_partner.png";
import { authService } from "../services/authService";
import NavBar from '../shared/navBar';
import urlHomeBase from '../services/urlBase';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const PartnerInfo = () => {
    const [msgToast, setmsgToast] = useState('');
    const [openToast, setOpenToast] = useState(false);
    const [error, setError] = useState(false);
    const [returnto, setReturnto] = useState(false);
    const [customValue, setCustomValue] = useState('');
    const [lstProvOrState, setlstProvOrState] = useState([]);
    const [lstPartnerType, setlstPartnerType] = useState([]);
    const [lstCities, setlstcities] = useState([]);
    const [addressId, setAddressId] = useState(null);
    const [logo, setLogo] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const navigate = useNavigate();
    const [urlBase, setUrlBase] = useState();
    const [userRole, setUserRole] = useState('');
    
    const initialState = {
        name: "",
        partnerType: {
          type: ""
        },
        partnerAddress: {
          streetAddress: "",
          extendedAddress: "",
          postalCode: "",
          country: "",
          provinceOrState: {
            name: ""
          },
          city: {
            name: ""
          }
        },
        active: false
      };

    const [fields, setFields] = useState(initialState);
    


    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const file64 = await readFileAsBase64(file);
                const thumb64 = await createThumbnail(file, 100, 100);

                setFields(prev => ({ ...prev, logo: file64 }));
                setLogo(file);
                setThumbnail(thumb64);
            } catch (error) {
                console.error("Erro ao processar imagem:", error);
            }
																		
							   
        }
    };

    const handleChange = (e, field, newValue = null) => {
        setFields(prev => ({
            ...prev,
            [field]: field === 'active' ? e.target.checked : (newValue !== null ? newValue : e.target.value),
        }));
    };

    const handleInputChange = (e, newValue, field) => {
        setFields(prev => ({
            ...prev,
            [field]: newValue,  // Directly set the new input value
        }));
    };

    const handleCancel = async () => {
        navigateTo();
    };

    const navigateTo = async () => {
        try {
            const storeduser = authService.getUserData();
            const userbaseUrl = urlHomeBase[storeduser.role];
            //setUrlBase(userbaseUrl);
            navigate(userbaseUrl);
        } catch (error) {
            console.error('Error fetching user data:', error);
            // Optionally, handle the error by showing a message to the user
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const storeduser = authService.getUserData();

                const [partnertypes, provstates, cities] = await Promise.all([
                    apiService.partnertypeall(),
                    apiService.provinceorstateall(),
                    apiService.citiesall()
                ]);

                const mapData = (data, key) => data.map(item => item[key]);

                setlstPartnerType(mapData(partnertypes.data, 'type'));
                setlstProvOrState(mapData(provstates.data, 'name'));
                setlstcities(mapData(cities.data, 'name'));

                if (storeduser.selectedPartner !== '0') {
                    const partnermailData = { param: storeduser.selectedPartner };
                    const partneradm = await apiService.admbypartnerid(partnermailData);
                    const partner = await apiService.partnerbyid(storeduser.selectedPartner);
                    const partnerTypeData = { param: String(partner.data.partnerTypeId) };
                    const partnerType = await apiService.partnertypebyid(partnerTypeData);
                    setAddressId(partner.data.addressId);

                    setFields({
                        id: partner.data.id,
                        active: partner.data.active,
                        email: partneradm?.data?.email || '',
                        name: partner.data.name,
                        partnerType: partnerType.data.type,
                        addressId: addressId,
                        streetAddress: partner?.data?.partnerAddress?.streetAddress || '',
                        extendedAddress: partner?.data?.partnerAddress?.extendedAddress || '',
                        provinceOrState: String(partner?.data?.partnerAddress?.provinceOrState.name || ''),
                        city: String(partner?.data?.partnerAddress?.city?.name || ''),
                        country: partner?.data?.partnerAddress?.country || '',
                        postalCode: partner?.data?.partnerAddress?.postalCode || '',
                        site: partner?.data?.site || '',
                        logo: partner?.data?.logo || ''
                    });
                }
            } catch (error) {
                setReturnto(true);
                setmsgToast("Unable to access data. Please contact administrator Myomar");
                setError(true);
                setOpenToast(true);
            }
        }

        fetchData();
    }, []);

    const createThumbnail = async (file, maxWidth = 100, maxHeight = 100) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);

                    resolve(canvas.toDataURL("image/png"));
                };

                img.onerror = (error) => reject(error);
                img.src = event.target.result;
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };
        



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const setDefault = (value) => (value === null || value === undefined ? '' : value);

            const updatedFields = {
                Active: setDefault(fields.active),
                Email: setDefault(fields.email).toLowerCase(),
                Name: setDefault(fields.name).toUpperCase(),
                PartnerType: {
                    type: setDefault(fields.partnerType).toUpperCase(),
                },
                partnerAddress: {
                    streetAddress: setDefault(fields.streetAddress).toUpperCase(),
                    extendedAddress: setDefault(fields.extendedAddress).toUpperCase(),
                    provinceOrState: {
                        name: setDefault(fields.provinceOrState).toUpperCase(),
                    },
                    city: {
                        name: setDefault(fields.city).toUpperCase(),
                    },
                    country: setDefault(fields.country).toUpperCase(),
                    postalCode: setDefault(fields.postalCode).toUpperCase(),
                },
                Logo: setDefault(fields.logo),
                Site: setDefault(fields.site).toLowerCase(),
            };
            const storeduser = authService.getUserData();
            if (storeduser.selectedPartner !== '0') {
                updatedFields.Id = Number(storeduser.selectedPartner);
                updatedFields.partnerAddress.Id = Number(addressId);
            }

            await apiService.partneradd(updatedFields);
            setReturnto(true);
            setError(false);
            setmsgToast('Data updated successfully.');
            setOpenToast(true);

        } catch (error) {
            setReturnto(false);
            setError(true);
            setmsgToast(error.message + '<br/>Check the entered data.');
            setOpenToast(true);
        }
    };

    const readFileAsBase64 = async (logo) => {
        if (logo.size > 100000) {
            setReturnto(false);
            setError(true);
            setmsgToast('The file is very large.<br/>Reduce the file size and try again.');
            setOpenToast(true);
        }
        else {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const arrayBuffer = event.target.result;
                    const bytes = new Uint8Array(arrayBuffer);
                    const base64String = btoa(String.fromCharCode.apply(null, bytes));
                    resolve(base64String);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsArrayBuffer(logo);
            });
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
        if (returnto) {
            navigateTo();
        }
    };

    return (
        <>
        <Header />
        { fields.name ? (
            <div className="bgColor">
                <NavInfo
                    currentLinkText="Your Business"
                    title="Your Business"
                    canShowButton={false}
                />
                <Box sx={{ display: 'flex' }}>
                    <SideBar />
                    <Box
                        component="main" sx={{
                            flexGrow: 1,
                            overflow: 'visible',
                            p: 3,
                        }}>
                        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <p className="primary-text">
                                    Please fill out the following information
                                </p>
                                <form onSubmit={handleSubmit} autoComplete='off'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                value={fields.email || '' }
                                                onChange={e => handleChange(e, 'email')}
                                                margin="normal"
                                                InputProps={{ readOnly: fields.id > 0 ? true : false }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Business Name"
                                                value={fields.name || '' }
                                                onChange={e => handleChange(e, 'name')}
                                                margin="normal"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                fullWidth
                                                options={lstPartnerType}
                                                value={fields.partnerType || '' }
                                                onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'partnerType')}
                                                onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, 'partnerType')}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Business Type"
                                                        margin="normal"
                                                        required
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Street Address"
                                                value={fields.streetAddress || '' }
                                                onChange={e => handleChange(e, 'streetAddress')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Extended Address"
                                                value={fields.extendedAddress || '' }
                                                onChange={e => handleChange(e, 'extendedAddress')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                fullWidth
                                                options={lstProvOrState}
                                                value={fields.provinceOrState || '' }
                                                onChange={(e, newValue) => handleChange({ target: { value: newValue } }, 'provinceOrState')}
                                                onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, 'provinceOrState')}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Province Or State"
                                                        margin="normal"
                                                        required
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                fullWidth
                                                options={lstCities}
                                                value={fields.city || '' }
                                                onChange={(e, newValue) => handleChange(e, 'city', newValue)}
                                                onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, 'city')}  // For typed values
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Location"
                                                        margin="normal"
                                                        required
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Country"
                                                margin="normal"
                                                select
                                                value={fields.country || '' }
                                                onChange={e => handleChange(e, 'country')}
                                                required
                                            >
                                                <MenuItem value={"CA"}>CANADA</MenuItem>
                                                <MenuItem value={"US"}>UNITED STATES</MenuItem>
                                                <MenuItem value={"BR"}>BRAZIL</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Postal Code"
                                                value={fields.postalCode || '' }
                                                onChange={e => handleChange(e, 'postalCode')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Website"
                                                value={fields.site || '' }
                                                onChange={e => handleChange(e, 'site')}
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid container spacing={3} className='griduploadimage' >
                                            <Grid item xs={12} md={6} className='imageupload'>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="contained-button-file"
                                                    type="file"
                                                    onChange={handleLogoChange}
                                                />

                                                <Grid spacing={6}>
                                                    <label htmlFor="contained-button-file">
                                                        <Button variant="contained" component="span" style={{
                                                            backgroundColor: "#1789C6",
                                                            height: "40px",
                                                            width: "150px",
                                                            color: "white",
                                                            fontWeight: 'bold',
                                                            borderRadius: "20px"
                                                        }}>
                                                            Upload Image
                                                        </Button>
                                                    </label>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} md={6} className='imageupload'>
                                                <div style={{ marginBottom: 'auto' }}>
                                                <img src={fields.logo ? `data:image/png;base64,${fields.logo}` : LogoImage} className="logo-image-lg" />
                                                {thumbnail && <img src={thumbnail} alt="Thumbnail" className="logo-thumbnail" />}
                                                </div>
                                                <div style={{ marginTop: 'auto' }}>
                                                    {logo && (
                                                        <p className='smalltext'>{logo.name} - Size: {logo.size} bytes</p>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mt: 2
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button
                                                        variant='contained'
                                                        type="submit"
                                                        sx={{
                                                            backgroundColor: "rgba(197, 220, 255, 1)",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                            fontFamily: "Roboto",
                                                            borderColor: "rgba(197, 220, 255, 1)"
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button
                                                        variant='contained'
                                                        type="button"
                                                        onClick={handleCancel}
                                                        sx={{
                                                            backgroundColor: "rgba(197, 220, 255, 1)",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                            fontFamily: "Roboto",
                                                            borderColor: "rgba(197, 220, 255, 1)"
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>) : null
}
            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default PartnerInfo;
