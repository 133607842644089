import React, { useState, useEffect } from 'react';
import {
  Container, Paper, Grid, TextField, Pagination, IconButton, Tooltip,
  Box, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Toast from '../shared/toast';
import SideBar from "../shared/sideBar";
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";
import { DataGrid } from '@mui/x-data-grid';
import { apiService } from '../services/apiService.js';
import { authService } from '../services/authService';

const ClientTestReport = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setMsgToast] = useState('');
  const [error, setError] = useState(false);

  // Fetch Data
  useEffect(() => {
    async function fetchData() {
      try {
        const userData = await authService.getUserData();
        const res = await apiService.getTestResultsByUserId(userData.id);

        const testScores = res.data.map(ts => ({
          id: ts.id,
          testKitId: ts.testKitId,
          testNumber: ts.testNumber,
          muscle: ts.muscle,
          sexAtBirth: ts.user.sexAtBirth,
        }));
        setRows(testScores);
        setFilteredRows(testScores);
      } catch (err) {
        setMsgToast("Failed to fetch data");
        setError(true);
        setOpenToast(true);
      }
    }
    fetchData();
  }, []);

  // Search Handling
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchText(query);
    const filteredData = rows.filter(row =>
      row.testKitId.toLowerCase().includes(query)
    );
    setFilteredRows(filteredData);
  };

  const clearSearch = () => {
    setSearchText('');
    setFilteredRows(rows);
  };

  // 🔽 Função para baixar o relatório
  const handleDownloadReport = async (testId) => {
    try {
      const testResultResponse = await apiService.getTestResultById(testId);
      const testResult = testResultResponse.data;

      if (!testResult || !testResult.fileLocation) {
        setMsgToast("Report file not found.");
        setError(true);
        setOpenToast(true);
        return;
      }

      const response = await apiService.downloadTestReport(testId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = testResult.fileLocation.split('/').pop();

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setMsgToast("Report downloaded successfully.");
      setError(false);
    } catch (error) {
      console.error("Error downloading report:", error);
      setMsgToast("Error downloading report.");
      setError(true);
    } finally {
      setOpenToast(true);
    }
  };

  // 📊 Colunas da Tabela
  const columns = [
    { field: 'testKitId', headerName: 'TestKit', minWidth: 50 },
    { field: 'testNumber', headerName: 'Test Number', minWidth: 50 },
    {
      field: 'report',
      headerName: 'Report',
      align: 'center',
      renderCell: (params) => (
        <Tooltip title="Download Report">
          <IconButton 
            onClick={() => handleDownloadReport(params.row.id)}
            sx={{
              backgroundColor: "rgba(197, 220, 255, 1)",
              color: "#000000",
              fontWeight: "bold",
              borderColor: "rgba(197, 220, 255, 1)",
              "&:hover": {
                backgroundColor: "rgba(150, 200, 255, 1)"
              }
            }}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      ),
      maxWidth: 100,
    },
  ];

  return (
    <>
      <Header />
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'visible', p: 3 }}>
            <NavInfo currentLinkText="Test Report" title="Test Report" canShowButton={false} />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Paper sx={{ p: 2 }}>
                {/* 🔎 Search */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search by TestKit"
                      value={searchText}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        endAdornment: (
                          <IconButton onClick={clearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                {/* 📊 Data Grid */}
                <Box sx={{ mt: 2 }}>
                  <DataGrid
                    rows={filteredRows}
                    columns={columns.map((column) => ({
                      ...column,
                      flex: 1,
                      p: 2,
                    }))}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5]}
                    disableSelectionOnClick
                    checkboxSelection={false}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": { fontWeight: "bold" },
                      backgroundColor: "#ffffff",
                    }}
                  />
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
      <Toast open={openToast} handleClose={() => setOpenToast(false)} message={msgToast} severity={error ? 'error' : 'success'} />
    </>
  );
};

export default ClientTestReport;
