import React, { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import {
    Container, Grid, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TextField, Typography, InputAdornment, IconButton, TablePagination, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import { useParams, useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import Toast from '../shared/toast';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Tooltip from '@mui/material/Tooltip';

const PartnerScanKit = ({ partnerId, handleCloseScankitModal }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(false);
    const [testKits, setTestKits] = useState([]);
    const [testKitNumber, setTestKitNumber] = useState("");
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('Kit Saved.');

    const [partnerName, setPartnerName] = useState();
    const navigate = useNavigate();

    // State for delete confirmation dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedKitId, setSelectedKitId] = useState(null);

    // Pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchTestKits = async () => {
            try {
                const partnerData = await apiService.partnerbyid(partnerId);
                setPartnerName(partnerData.data.name);
                const response = await apiService.getPartnerTestKits(partnerId);
                setTestKits(response.data);
            } catch (err) {
                setmsgToast(err.response.data);
            }
        };
        fetchTestKits();
    }, [partnerId]);

    const handleAdd = async () => {
        try {
            if (testKitNumber !== '') {
                await apiService.addPartnerTestKit({
                    partnerId: Number(partnerId),
                    testKitNumber: testKitNumber,
                    dateAdded: new Date().toISOString()
                });
                setError(false);
                setmsgToast("Kit added to Partner.");
                setTestKitNumber("");
                const response = await apiService.getPartnerTestKits(partnerId);
                setTestKits(response.data);
            }
            else {
                setError(true);
                setmsgToast("Kit identification can´t be null.");
            }
        } catch (err) {
            setError(true);
            setmsgToast(err.response.data);
        }
        finally {
            setOpen(false);
            setOpenToast(true);
            setRefresh(!refresh);
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
        setError(false);
    };

    // Function to handle opening the confirmation dialog
    const handleOpenDeleteDialog = (id) => {
        setSelectedKitId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async () => {
        try {
            await apiService.deletePartnerTestKit(selectedKitId);
            const response = await apiService.getPartnerTestKits(partnerId);
            setTestKits(response.data);
            setError(false);
            setmsgToast("Kit deleted.")
        } catch (err) {
            setError(true);
            setmsgToast(err.response.data);
        } finally {
            setOpen(false);
            setOpenToast(true);
            setOpenDialog(false);  // Close the dialog after delete
            setRefresh(!refresh);
        }
    };

    const handleCancel = async () => {
        navigate("/managepartner");
    };

    // Handle pagination page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div>
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>Test Kits</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Scan or Enter Test Kit Number"
                                value={testKitNumber}
                                onChange={(e) => setTestKitNumber(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && handleAdd()}
                                autoFocus
                                inputProps={{
                                    maxLength: 8, // Limita o número de caracteres para 8
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                color="primary"
                                                onClick={() => setTestKitNumber('')}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '.MuiInputAdornment-root': {
                                        margin: '0 4px',
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ marginTop: "10px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                sx={{
                                    width: "150px",
                                    backgroundColor: "rgba(197, 220, 255, 1)",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    fontFamily: "Roboto",
                                    borderColor: "rgba(197, 220, 255, 1)"
                                }}
                            >
                                ADD
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6" sx={{ p: 1 }}>{partnerName}</Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Test Kit Number</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {testKits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((kit) => (
                                                <TableRow key={kit.id}>
                                                    <TableCell>{kit.testKitNumber}</TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Click to Delete Kit Info">
                                                            <Button
                                                                style={{ margin: "0px 15px 0px 0px", width: 30, height: 30, paddingTop: 10 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={() => handleOpenDeleteDialog(kit.id)}
                                                            >
                                                                <Box position="relative" display="inline-block">
                                                                    <DeleteForeverOutlinedIcon />
                                                                </Box>
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={testKits.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[]}  // Disable the rows per page options
                                    labelRowsPerPage=""       // Hide the "Rows per page" label
                                    sx={{
                                        '& .MuiTablePagination-selectLabel': {
                                            display: 'none',      // Hide the "Rows per page" text
                                        },
                                        '& .MuiTablePagination-displayedRows': {
                                            display: 'none',      // Hide the "6–10 of 22" text
                                        },
                                        '& .MuiInputBase-root': {
                                            display: 'none',      // Hide the rows per page dropdown
                                        },
                                    }}
                                />




                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    variant='contained'
                                    type="button"
                                    onClick={handleCloseScankitModal}
                                    sx={{
                                        backgroundColor: "rgba(197, 220, 255, 1)",
                                        color: "#000000",
                                        fontWeight: "bold",
                                        fontFamily: "Roboto",
                                        borderColor: "rgba(197, 220, 255, 1)"
                                    }}
                                >
                                    Done
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this test kit?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default PartnerScanKit;
