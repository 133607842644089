import React, { useState, useEffect } from 'react';
import { 
  Button, Container, Paper, Grid, TextField, Typography, IconButton, Pagination, Tooltip, Box, Dialog, DialogTitle, 
  DialogContentText, DialogContent, DialogActions 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Toast from '../shared/toast';
import NavBar from "../shared/navBar";
import SideBar from "../shared/sideBar";
import GaugeChartDialog from './GaugeChartDialog';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const TestScore = () => {
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [error, setError] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pastedScores, setPastedScores] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await apiService.getTestScores();
        const testScores = res.data.map(ts => ({
          id: ts.id,
          name: ts.user.firstName + " " + ts.user.lastName,
          sexAtBirth: ts.user.sexAtBirth,
          testKitId: ts.testKitId,
          testNumber: ts.testNumber,
          metabolism: ts.metabolism || "",
          behaviour: ts.behaviour || "",
          muscle: ts.muscle || "",
          performance: ts.performance || "",
          nutrition: ts.nutrition || "",
          efficiency: ts.efficiency || "",
          recovery: ts.recovery || "",
        }));
        setRows(testScores);
        setFilteredRows(testScores);

        const user = await authService.getUserData();
        setUserRole(user.role);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [refresh]);

  const handleOpenReportDialog = (row) => {
    setSelectedRowData(row);
    setDialogTitle(`${row.name} - ${row.testKitId}`);
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };


  const handlePasteScores = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const rows = text.split('\n').filter(row => row.trim() !== '');

      const cont = rows[0].split('\t').filter(value => value.trim() !== '').length;
      if (cont !== 8) {
        throw new Error("Invalid Data. Try copy again.");
      }
      else {
        const parsedData = rows.map(row => row.split('\t')).map(row => ({
          testKitId: row[0],
          metabolism: parseFloat(row[1]).toFixed(5),
          behaviour: parseFloat(row[2]).toFixed(5),
          muscle: parseFloat(row[3]).toFixed(5),
          performance: parseFloat(row[4]).toFixed(5),
          nutrition: parseFloat(row[5]).toFixed(5),
          efficiency: parseFloat(row[6]).toFixed(5),
          recovery: parseFloat(row[7]).toFixed(5),
        }));
        setPastedScores(parsedData);
        setTableData(parsedData);
        setOpenImportModal(true);
      }
    } catch (err) {
      setmsgToast("Failed to paste data. Try copying again.");
      setOpenToast(true);
      setError(true);
    }
    
  };

  const handleImportScores = async () => {
    try {
      await apiService.importTestScores(pastedScores);
      setmsgToast("Scores imported successfully!");
      setError(false);
      setRefresh(!refresh);
    } catch (err) {
      setmsgToast("Failed to import scores.");
      setError(true);
    }
    setOpenToast(true);
    setOpenImportModal(false);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchText(query);
    const filteredData = rows.filter(row =>
      row.name.toLowerCase().includes(query) ||
      row.testKitId.toLowerCase().includes(query)
    );
    setFilteredRows(filteredData);
  };

  const clearSearch = () => {
    setSearchText('');
    setFilteredRows(rows);
  };

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 150 },
    { field: 'testKitId', headerName: 'TestKit', maxWidth: 90 },
    { field: 'testNumber', headerName: '#', maxWidth: 50 },
    { field: 'metabolism', headerName: 'Metabolism', align: 'center' },
    { field: 'behaviour', headerName: 'Behaviour', align: 'center' },
    { field: 'muscle', headerName: 'Muscle', align: 'center' },
    { field: 'performance', headerName: 'Performance', align: 'center' },
    { field: 'nutrition', headerName: 'Nutrition', align: 'center' },
    { field: 'efficiency', headerName: 'Efficiency', align: 'center' },
    { field: 'recovery', headerName: 'Recovery', align: 'center' },
    {
      field: 'report',
      headerName: 'Report',
      align: 'center',
      renderCell: (params) => (
        <Tooltip title="View Report">
          <IconButton
            onClick={() => handleOpenReportDialog(params.row)}
            sx={{
              backgroundColor: "rgba(197, 220, 255, 1)",
              color: "#000000",
              fontWeight: "bold",
              borderColor: "rgba(197, 220, 255, 1)",
              "&:hover": {
                backgroundColor: "rgba(150, 200, 255, 1)"
              }
            }}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      ),
      maxWidth: 90,
    },
  ];

  return (
    <>
    <Header />
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'visible',
            p: 3,
          }}>
            <NavInfo
                currentLinkText="Test Scores"
                title="Test Scores"
                canShowButton={false}
            />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search Test Scores"
                      value={searchText}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        endAdornment: (
                          <IconButton onClick={clearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ marginTop: "10px" }}>
                    <Tooltip title="Paste Test Scores">
                      <IconButton onClick={handlePasteScores} sx={{
                        backgroundColor: "rgba(197, 220, 255, 1)",
                        color: "#000000",
                        fontWeight: "bold",
                        borderColor: "rgba(197, 220, 255, 1)",
                        "&:hover": {
                          backgroundColor: "rgba(150, 200, 255, 1)"
                        }
                      }}>
                        <ContentPasteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                <DataGrid
                    fullWidth
                    rows={filteredRows}
                    columns={columns.map((column) => ({
                      ...column,
                      flex: 1, // Allows each column to auto-size based on content
                      p: 2

                    }))}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    disableSelectionOnClick // Disables row selection on click
                    checkboxSelection={false} // Removes the checkbox column
                    components={{
                      Pagination: () => (
                        <Pagination
                          count={Math.ceil(filteredRows.length / 5)}
                          shape="rounded"
                          hidePrevButton={filteredRows.length <= 5} // Hide previous button if only one page
                          hideNextButton={filteredRows.length <= 5} // Hide next button if only one page
                        />
                      ),
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal"
                      },
                      '& .MuiTablePagination-selectLabel': {
                        display: 'none',      // Hide the "Rows per page" text
                      },
                      '& .MuiTablePagination-displayedRows': {
                        display: 'none',      // Hide the "6–10 of 22" text
                      },
                      '& .MuiInputBase-root': {
                        display: 'none',      // Hide the rows per page dropdown
                      },
                      backgroundColor: "#ffffff",
                      "& .MuiDataGrid-columnHeaders": {
                        fontWeight: "bold",  // Set the font weight of headers to bold
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",  // Ensure text in headers is bold
                      },
                    }}
                  />
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
      <Dialog open={openImportModal} onClose={() => setOpenImportModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Import Scores from Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>Importing scores data from the spreadsheet</DialogContentText>
          <div>
            {tableData.length > 0 && (
              <table border="1">
                <thead>
                  <tr>
                    {['TestKitId', 'Metabolism', 'Behaviour', 'Muscle', 'Performance', 'Nutrition', 'Efficiency', 'Recovery'].map((col, index) => (
                      <th key={index} style={{ width: '150px', textAlign: 'center' }}>{col}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.values(row).map((cell, cellIndex) => (
                        <td key={cellIndex} style={{ width: '150px', textAlign: 'right' }}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImportScores} color="primary">Save</Button>
          <Button onClick={() => setOpenImportModal(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <GaugeChartDialog
        open={openReportDialog}
        handleClose={handleCloseReportDialog}
        data={selectedRowData}
        title={dialogTitle}
      />
      <Toast open={openToast} handleClose={() => setOpenToast(false)} message={msgToast} severity={error ? 'error' : 'success'} />
    </>
  );
};

export default TestScore;
