import { Navigate, useLocation } from "react-router-dom";

const getUserRole = () => {
  return localStorage.getItem('role') || sessionStorage.getItem('role');
};

const isValidToken = (token) => {

  return token === "valid-token"; 
};

export const RoleBasedRoute = ({ children, allowedRoles, allowPublic = false }) => {
  const location = useLocation();
  const userRole = getUserRole();
  const isQuestionnaireRoute = location.pathname === "/questionnaireQuestions";
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  if (isQuestionnaireRoute && token && isValidToken(token)) {
    return children;
  }

  if (allowPublic || allowedRoles.includes('Public') || (userRole && allowedRoles.includes(userRole))) {
    return children;
  }

  return <Navigate to="/notfound" />;
};
