import React from "react";
import Button from "@mui/material/Button";

import { ReactComponent as ChevronRight } from "../assets/icons/Arrow/chevron-right.svg";
import { ReactComponent as UserPlus } from "../assets/icons/Users/user-plus-01.svg";

import "./NavInfo.scss";

const NavInfo = ({
  currentLinkText,
  title,
  buttonTitle,
  onClick,
  canShowButton = true,
}) => {
  return (
    <div className="navInfo">
      <div className="navInfo__details">
        <nav className="navInfo__breadcrumb">
          <ul className="navInfo__breadcrumb__list">
            <li className="navInfo__breadcrumb__item">
              <ChevronRight className="navInfo__breadcrumb__icon" />
            </li>
            <li >
              <h1 className="navInfo__title">{title}</h1>
            </li>
          </ul>
        </nav>

      </div>

      {canShowButton && (
        <div className="navInfo__button">
          <Button
            variant="primary"
            sx={{
              background: "#156B79",

              "&:hover": {
                background: "#156B79",
              },
            }}
            onClick={onClick}
          >
            <UserPlus />
            <span>{buttonTitle}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default NavInfo;
