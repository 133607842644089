import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography } from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { useNavigate } from "react-router-dom";
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const Questionnaire = () => {
    const [questionnaires, setQuestionnaires] = useState([]);
    const [testResult, setTestResult] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                const userData = await authService.getUserData();
                const testResult = await apiService.getTestResultQuestionnaireUserActive(userData.id);
                setTestResult(testResult.data);
                const response = await apiService.getUserQuestionnaires(userData.id);
                setQuestionnaires(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchQuestionnaires();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    const startQuestionnaire = (questionnaireId, questionnaireUserId) => {
        navigate(`/questionnaireQuestions/${questionnaireId}/${questionnaireUserId}`);
    };

    return (
        <>
        <Header />
        <div className="bgColor">
            <Box sx={{ display: 'flex' }}>
                <SideBar />
                <Box
                    component="main" sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'visible',
                        p: 3,
                    }}>
                <NavInfo
                currentLinkText="Questionnaires"
                title="Questionnaires"
                canShowButton={false}
                />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Paper sx={{ p: 2 }}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
                                        <TableRow>
                                            <TableCell>Test Kit Id</TableCell>
                                            <TableCell>Number</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {testResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tresult) => (
                                            <TableRow key={tresult.questionnaireUser.id}>
                                                <TableCell>{tresult?.testKitId}</TableCell>
                                                <TableCell>{tresult?.testNumber}</TableCell>
                                                <TableCell>{tresult.questionnaireUser.isComplete ? 'Complete' : 'Incomplete'}</TableCell>
                                                <TableCell>
                                                    {!tresult.questionnaireUser.isComplete && (
                                                        <div className="navInfo__button">
                                                            <Button
                                                                variant="primary"
                                                                sx={{
                                                                background: "#156B79",
                                                
                                                                "&:hover": {
                                                                    background: "#156B79",
                                                                },
                                                                }}
                                                                onClick={() => startQuestionnaire(tresult.questionnaireUser.questionnaireId, tresult.questionnaireUser.id)}
                                                            >
                                                                <span>Start</span>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={questionnaires.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[]}  // Disable the rows per page options
                                labelRowsPerPage=""       // Hide the "Rows per page" label
                                sx={{
                                    '& .MuiTablePagination-selectLabel': {
                                        display: 'none',      // Hide the "Rows per page" text
                                    },
                                    '& .MuiTablePagination-displayedRows': {
                                        display: 'none',      // Hide the "6–10 of 22" text
                                    },
                                    '& .MuiInputBase-root': {
                                        display: 'none',      // Hide the rows per page dropdown
                                    },
                                }}
                            />
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </div>
        </>
    );
};

export default Questionnaire;
