import Toast from "../shared/toast";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Grid, Stack } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, NavLink } from 'react-router-dom';

import '../global.css';
import './Home.css';
import NavBar from '../shared/navBar';

const NotFound = () => {

    const navigate = useNavigate();

    return (

        <div>
            <div className="home-container">
            {<NavBar />}
                <div className="home-banner-container">
                    <div className="home-bannerImage-container">
                        <img src={BannerBackground} alt="" />
                    </div>
                    <div className="home-text-section">
                        <h1 className="primary-heading">
                        Oopss!</h1>
                        I didn't find the requested page.
                        <br/>
                        <button className="primary-button" onClick={() => navigate("/")}>Go to HomePage</button>
                     </div>
                    <div className="home-image-section">
                        <img src={BannerImage} alt="" />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NotFound;
